import React from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { contact } from "../../utilities/constants";
import GlobalButton from "../GlobalButton";
import Icon from "../Icon";

const DialogBox = () => {
  return (
    <div>
      <Dialog className="rounded-lg">
        <DialogTrigger asChild>
          <GlobalButton svgName="Call" className="mr-0" />
        </DialogTrigger>
        <DialogContent className="md:max-w-md">
          <DialogHeader className='mb-8 text-center'>
            <DialogTitle className='font-bold'>Contact Us</DialogTitle>
          </DialogHeader>
          <div className="w-full space-y-2 h-fit">
            {contact.map((items) => {
              return (
                <div className="flex">
                  <div className="flex items-center justify-center w-full mb-6 space-x-3 h-fit">
                    <div className="w:1/3">
                      <Icon
                        svg={items.icon}
                        className={"w-12 h-12"}
                        alt="img-icon"
                      />
                    </div>
                    <div className="flex flex-col w-2/3">
                      <span className="text-base font-semibold">
                        {items.Header}
                      </span>
                      <span>
                        {items.title} : {items.number}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" variant="default" className="w-1/2">
                Close
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogBox;
