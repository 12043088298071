import image1 from "../../static/images/Attractions/image1.png";
import image2 from "../../static/images/Attractions/image2.png";
import image3 from "../../static/images/Attractions/image3.jpg";
import image4 from "../../static/images/Attractions/image4.jpg";
import image5 from "../../static/images/Attractions/image5.jpg";
import image6 from "../../static/images/Attractions/image6.jpg";
import image7 from "../../static/images/Attractions/image7.jpg";
import image8 from "../../static/images/Attractions/image8.jpg";
import image9 from "../../static/images/Attractions/image9.jpg";
import image10 from "../../static/images/Attractions/image10.jpg";
import image11 from "../../static/images/Attractions/image11.jpg";
import image12 from "../../static/images/Attractions/image12.jpg";
import image13 from "../../static/images/Attractions/image13.jpg";
import image14 from "../../static/images/Attractions/image14.jpg";
import FS1 from "../../static/images/Rooms/FamilySuite/image1.png";
import FS2 from "../../static/images/Rooms/FamilySuite/image2.jpg";
import FS3 from "../../static/images/Rooms/FamilySuite/image3.jpg";
import FS4 from "../../static/images/Rooms/FamilySuite/image4.jpg";
import FS5 from "../../static/images/Rooms/FamilySuite/image5.jpg";
import FS6 from "../../static/images/Rooms/FamilySuite/image6.jpg";
import FS7 from "../../static/images/Rooms/FamilySuite/image7.jpg";
import FS8 from "../../static/images/Rooms/FamilySuite/image8.jpg";
import DKS1 from "../../static/images/Rooms/DeluxeKingSuite/image1.png";
import DKS2 from "../../static/images/Rooms/DeluxeKingSuite/image2.jpg";
import DKS3 from "../../static/images/Rooms/DeluxeKingSuite/image3.jpg";
import DKS4 from "../../static/images/Rooms/DeluxeKingSuite/image4.jpg";
import DKS5 from "../../static/images/Rooms/DeluxeKingSuite/image5.jpg";
import DKS6 from "../../static/images/Rooms/DeluxeKingSuite/image6.jpg";
import DKS7 from "../../static/images/Rooms/DeluxeKingSuite/image7.jpg";
import DKS8 from "../../static/images/Rooms/DeluxeKingSuite/image8.jpg";
import DKS9 from "../../static/images/Rooms/DeluxeKingSuite/image9.jpg";
import DQR1 from "../../static/images/Rooms/DeluxeQueenRoom/image1.jpg";
import DQR2 from "../../static/images/Rooms/DeluxeQueenRoom/image2.jpg";
import DQR3 from "../../static/images/Rooms/DeluxeQueenRoom/image3.jpg";
import DQR4 from "../../static/images/Rooms/DeluxeQueenRoom/image4.jpg";
import DQR5 from "../../static/images/Rooms/DeluxeQueenRoom/image5.jpg";
import DQR6 from "../../static/images/Rooms/DeluxeQueenRoom/image6.jpg";
import DQR7 from "../../static/images/Rooms/DeluxeQueenRoom/image7.jpg";
import DQR8 from "../../static/images/Rooms/DeluxeQueenRoom/image8.jpg";
import DQR9 from "../../static/images/Rooms/DeluxeQueenRoom/image9.jpg";
import DQR10 from "../../static/images/Rooms/DeluxeQueenRoom/image10.png";
import DQR11 from "../../static/images/Rooms/DeluxeQueenRoom/image11.jpg";
import DQR12 from "../../static/images/Rooms/DeluxeQueenRoom/image12.jpg";
import DQR13 from "../../static/images/Rooms/DeluxeQueenRoom/image13.jpg";
import DQR14 from "../../static/images/Rooms/DeluxeQueenRoom/image14.jpg";
import DQR15 from "../../static/images/Rooms/DeluxeQueenRoom/image15.jpg";
import DQR16 from "../../static/images/Rooms/DeluxeQueenRoom/image16.jpg";
import DQR17 from "../../static/images/Rooms/DeluxeQueenRoom/image17.jpg";
import DQR18 from "../../static/images/Rooms/DeluxeQueenRoom/image18.jpg";
import DQR19 from "../../static/images/Rooms/DeluxeQueenRoom/image19.jpg";
import DQS1 from "../../static/images/Rooms/DeluxeQueenSuite/image1.jpg";
import DQS2 from "../../static/images/Rooms/DeluxeQueenSuite/image2.jpg";
import DQS3 from "../../static/images/Rooms/DeluxeQueenSuite/image3.jpg";
import DQS4 from "../../static/images/Rooms/DeluxeQueenSuite/image4.jpg";
import DQS5 from "../../static/images/Rooms/DeluxeQueenSuite/image5.jpg";
import DQS6 from "../../static/images/Rooms/DeluxeQueenSuite/image6.jpg";
import DQS7 from "../../static/images/Rooms/DeluxeQueenSuite/image7.jpg";
import DQS8 from "../../static/images/Rooms/DeluxeQueenSuite/image8.png";
import DQS9 from "../../static/images/Rooms/DeluxeQueenSuite/image9.jpg";
import DKR1 from "../../static/images/Rooms/DeluxeKingRoom/image1.jpg";
import DKR2 from "../../static/images/Rooms/DeluxeKingRoom/image2.png";
import DKR3 from "../../static/images/Rooms/DeluxeKingRoom/image3.jpg";
import DKR4 from "../../static/images/Rooms/DeluxeKingRoom/image4.jpg";
import DKR5 from "../../static/images/Rooms/DeluxeKingRoom/image5.jpg";
import slidersImage1 from "../../static/images/Slider/image1.jpg";
import slidersImage2 from "../../static/images/Slider/image2.jpg";
import slidersImage3 from "../../static/images/Slider/image3.jpg";
import slidersImage4 from "../../static/images/Slider/image4.jpg";
import slidersImage5 from "../../static/images/Slider/image5.jpg";
import slidersImage6 from "../../static/images/Slider/image6.jpg";
import queenDeluxeRoom from "../../static/images/Rooms/DeluxeQueenRoom/main.jpg";
import deluxeKingRoom from "../../static/images/Rooms/DeluxeKingRoom/main.jpg";
import queenDeluxeSuite from "../../static/images/Rooms/DeluxeQueenSuite/main.jpg";
import deluxeKingSuite from "../../static/images/Rooms/DeluxeKingSuite/main.jpg";
import familySuite from "../../static/images/Rooms/FamilySuite/main.jpg";
import Clarendon_Hotel_1 from "../../static/images/Property/Clarendon_Hotel_1.jpg";
import Clarendon_Hotel_2 from "../../static/images/Property/Clarendon_Hotel_2.jpg";
import Clarendon_Hotel_3 from "../../static/images/Property/Clarendon_Hotel_3.jpg";
import Clarendon_Hotel_4 from "../../static/images/Property/Clarendon_Hotel_4.jpg";
import Clarendon_Hotel_5 from "../../static/images/Property/Clarendon_Hotel_5.jpg";
import Clarendon_Hotel_6 from "../../static/images/Property/Clarendon_Hotel_6.jpg";
import Clarendon_Hotel_7 from "../../static/images/Property/Clarendon_Hotel_7.jpg";
import Clarendon_Hotel_8 from "../../static/images/Property/Clarendon_Hotel_8.jpg";

export const email = "hello@hotelclarendon.com.au";

export const book =
  "https://bookings12.rmscloud.com/Search/Index/8481E4B51FF16D7B/1/";

export const FOOTER_LINKS = [
  { label: "Home", path: "/", submenu: null },
  { label: "Rooms", path: "/rooms", submenu: null },
  { label: "Attractions", path: "/attractions", submenu: null },
  { label: "Gallery", path: "/gallery", submenu: null },
  { label: "Contact Us", path: "/contact", submenu: null },
  { label: "About Us", path: "/about", submenu: null }
];


export const roomsImages = [
  {
    image: queenDeluxeRoom,
    title: 'Deluxe Queen Room',
    path: '/deluxe-queen-room',
    icons: [
      {
        icon: "Bath",
        info: "1 Bath"
      },
      {
        icon: "Bed",
        info: "1 Queen Bed"
      },
      {
        icon: "Guests2",
        info: "2 Guests"
      }
    ]
  },
  {
    image: deluxeKingRoom,
    title: 'Deluxe King Room',
    path: '/deluxe-king-room',
    icons: [
      {
        icon: "Bath",
        info: "1 Bath"
      },
      {
        icon: "Bed",
        info: "1 King Bed"
      },
      {
        icon: "Guests2",
        info: "2 Guests"
      }
    ]
  },
  {
    image: queenDeluxeSuite,
    title: 'Double Queen Suite',
    path: '/double-queen-suite',
    icons: [
      {
        icon: "Bath",
        info: "1 Bath"
      },
      {
        icon: "Bed",
        info: "2 Queen Bed"
      },
      {
        icon: "Guests3",
        info: "4 Guests"
      }
    ]
  },
  {
    image: deluxeKingSuite,
    title: 'Deluxe King Suite',
    path: '/deluxe-king-suite',
    icons: [
      {
        icon: "Bath",
        info: "1 Bath"
      },
      {
        icon: "Bed",
        info: "1 Super King Bed"
      },
      {
        icon: "Guests2",
        info: "2 Guests"
      }
    ]
  },
  {
    image: familySuite,
    title: 'Family Suite',
    path: '/family-suite',
    icons: [
      {
        icon: "Bath",
        info: "2 Baths"
      },
      {
        icon: "Bed",
        info: "2 Queen Beds"
      },
      {
        icon: "Guests3",
        info: "4 Guests"
      }
    ]
  },

]

export const ROOMS_CONTENT = [
  {
    image: queenDeluxeRoom,
    title: "Deluxe Queen Room",
    description:
      "Wind down and relax in our Deluxe Queen room after a long day. Overlooking York Street, the deluxe queen boasts views of Melbourne’s city skyline. Whether alone, with a partner or friend, look forward to relax on our Australian-made queen size mattresses after a long and productive day.",
    size: "30m²",
    link: "/deluxe-queen-room"
  },
  {
    image: deluxeKingRoom,
    title: "Deluxe King Room",
    description:
      "Spacious, comfortable and luxurious would be three words that describes our Deluxe King room.  Guests may enjoy a generous king-sized bed with a view looking over Clarendon Street.  This room is well-equipped for busy travellers seeking a well-deserved night of tranquillity.",
    size: "33m²",
    link: "/deluxe-king-room"
  },
  {
    image: deluxeKingSuite,
    title: "Deluxe King Suite",
    description:
      "Our King Suites are located on the corners of the property, overlooking both Clarendon and York Street.  In addition to one bathroom and a kitchenette, the bedroom comes with two single king beds.  This suite is perfect for friends travelling together who may relax in the luxurious and modern upholstery.",
    size: "47m²",
    link: "/deluxe-king-suite"
  },
  {
    image: queenDeluxeSuite,
    title: "Double Queen Suite",
    description:
      "Overlooking York Street, the Queen suite may accommodate up to 4 people.  This room features a generous shared living room with a couch and kitchenette, perfect for having friends over, or a private space to wind down after a long day.",
    size: "52m²",
    link: "/deluxe-queen-suite"
  },
  {
    image: familySuite,
    title: "Family Suite",
    description:
      "The ultimate experience for a family on vacation!  Our most spacious and expansive rooms offer two semi-private bedrooms connected to a shared living area, where guests may wind down, or make plans for the next day.  The suite is furnished with two Australian-made queen-sized beds and two private bathrooms, that will be a generous space for a travelling family.",
    size: "60m²",
    link: "/family-suite"
  }
];

export const images = [
  slidersImage1,
  slidersImage2,
  slidersImage3,
  slidersImage4,
  slidersImage5,
  slidersImage6
];

export const CAR_PARKING_INFO = [
  "There are plenty of 2 hours on street parking surrounding our Hotel.",
  "Overnight free on street parking available from 6pm to 8am and weekend free parking along York Street.",
  "We are located at the corner of Clarendon Street and York Street.",
  "You may also park at South Melbourne Market, Coles or Woolworth car parks. They are only one block away from us."
];


export const contact = [
  {
    icon: "Hotel",
    Header: "Accommodation",
    title: "Clarendon Hotel",
    number: "0458805395"
  }
]


export const AMENITIES = [
  {
    icon: "Bath",
    title: "Bath"
  },
  {
    icon: "tv",
    title: "tv"
  },
  {
    icon: "smoking",
    title: "smoking"
  },
  {
    icon: "wifi",
    title: "wifi"
  },

  {
    icon: "microwave",
    title: "microwave"
  },
  {
    icon: "airConditioner",
    title: "airConditioner"
  }
];
export const TRAVEL_INFORMATION = [
  {
    iconName: "Taxi",
    title: "Taxi",
    cost: 'This will cost you between <span style="font-weight: 700;">$50-80</span> depending on the traffic and weekend loadings and the type of Taxi vehicle you chose.'
  },
  {
    iconName: "UberTaxi",
    title: "Uber",
    cost: 'This will cost you between <span style="font-weight: 700;">$40-60</span> depending on the traffic and pea hours loadings and the type of Uber vehicle you chose.'
  },
  {
    iconName: "Bus",
    title: "Sky Bus",
    cost: 'This service picks you up from the airport and drops you off at Southern Cross Railway Station and operates 24/7, with buses running every 10 minutes. Tickets costing approximately <span style="font-weight: 700;">$24.00</span> per person. You don’t need to book ahead for this service as they depart frequently.',
    steps: [
      "Exit Southern Cross Station and head towards Spencer Street.",
      'Purchase a myki card from any nearby convenience store, newsagent, or train station. The cost for a full fare myki card is <span style="font-weight: 700;">$6</span>, and you can load it with the desired amount for your journey.',
      "Once you have your myki card, locate the tram stop closest to Southern Cross Station. The tram stop is located on Spencer Street, directly outside the station.",
      "Board tram route 12 or 12A towards St Kilda.",
      "Get off at stop 127 Clarendon St/York Street.",
      "Clarendon Hotel is located at the corner of Clarendon Street and York Street."
    ]
  }
];

export const ABOUTUS_INFORMATION = [
  "Clarendon Hotel is situated in the heart of South Melbourne, close to public transport and within easy reach of Melbourne's CBD.  Established in 1861 by Charles Chessell, it was initially known as the Albion hotel. Over the years, the hotel went through various name changes and rebuilt. Though not much of the original interior remains, this magnificent architectural masterpiece stood the test of time, and continues to reflect its late Victoria date of construction and ambience.  ",
  "The Clarendon Hotel offers an on-site restaurant, rooftop bar, free Wi-Fi, air-conditioning, Netflix/YouTube/Disney compatible 65’ TV per bedroom, in addition to desks, private bathrooms, bed linen and towels.  Some rooms also include a kitchenette with a microwav",
  "We offer a range of room types.  Depending on the purpose of your stay, we have a variety of room sizes to tailored to suit every need, all the while boasting luxurious interiors, meticulous cleanliness and a convenient location.  All our beds are proudly made in Australia",
  "Major destinations are all accessible within 10 minutes by car, including Crown Casino, National Gallery of Victoria, Melbourne Exhibition Centre, Collins Street and South Melbourne Market.  Both Flinders Street Station is 1.6 km. away and Southern Cross Station 1.5km are easily accessible by the number 12 tram directly outside the Clarendon Hotel. "
];

export const ROOMS_IMAGES = {
  deluxe_queen_room: [
    { src: queenDeluxeRoom },
    { src: DQR1 },
    { src: DQR2 },
    { src: DQR3 },
    { src: DQR4 },
    { src: DQR5 },
    { src: DQR6 },
    { src: DQR7 },
    { src: DQR8 },
    { src: DQR9 },
    { src: DQR10 },
    { src: DQR11 },
    { src: DQR12 },
    { src: DQR13 },
    { src: DQR14 },
    { src: DQR15 },
    { src: DQR16 },
    { src: DQR17 },
    { src: DQR18 },
    { src: DQR19 }
  ],
  family_suite: [
    { src: familySuite },
    { src: FS1 },
    { src: FS2 },
    { src: FS3 },
    { src: FS4 },
    { src: FS5 },
    { src: FS6 },
    { src: FS7 },
    { src: FS8 }
  ],
  deluxe_king_suite: [
    { src: deluxeKingSuite },
    { src: DKS1 },
    { src: DKS2 },
    { src: DKS3 },
    { src: DKS4 },
    { src: DKS5 },
    { src: DKS6 },
    { src: DKS7 },
    { src: DKS8 },
    { src: DKS9 }
  ],
  double_queen_suite: [
    { src: queenDeluxeSuite },
    { src: DQS1 },
    { src: DQS2 },
    { src: DQS3 },
    { src: DQS4 },
    { src: DQS5 },
    { src: DQS6 },
    { src: DQS7 },
    { src: DQS8 },
    { src: DQS9 }
  ],
  deluxe_king_room: [
    { src: deluxeKingRoom },
    { src: DKR1 },
    { src: DKR2 },
    { src: DKR3 },
    { src: DKR4 },
    { src: DKR5 }
  ]
};

export const ROOMS_AMENITIES = {
  deluxe_queen_room: [
    {
      iconName: "Bath",
      title: "Bath"
    },
    {
      iconName: "airConditioner",
      title: "Air Conditioner"
    },
    {
      iconName: "tv",
      title: "Television"
    },
    {
      iconName: "wifi",
      title: "Wireless Connection"
    },
    {
      iconName: "smoking",
      title: "Non-Smoking"
    },
    {
      iconName: "miniFridge",
      title: "Mini Fridge"
    },
    {
      iconName: "iron",
      title: "Iron"
    }, {
      iconName: "hairdryer",
      title: "Hair Dryer"
    }, {
      iconName: "ironBoard",
      title: "Iron Board"
    },
    {
      iconName: 'view',
      title: 'Views'
    },
  ],
  deluxe_king_room: [
    {
      iconName: "Bath",
      title: "Bath"
    },
    {
      iconName: "airConditioner",
      title: "Air Conditioner"
    },
    {
      iconName: "tv",
      title: "Television"
    },
    {
      iconName: "wifi",
      title: "Wireless Connection"
    },
    {
      iconName: "smoking",
      title: "Non-Smoking"
    },
    {
      iconName: "coffee",
      title: "Tea-Coffee Making"
    },
    {
      iconName: "miniFridge",
      title: "Mini Fridge"
    },
    {
      iconName: "iron",
      title: "Iron"
    }, {
      iconName: "hairdryer",
      title: "Hair Dryer"
    }, {
      iconName: "ironBoard",
      title: "Iron Board"
    }
  ],
  deluxe_king_suite: [
    {
      iconName: "Bath",
      title: "Bath"
    },
    {
      iconName: 'airConditioner',
      title: 'Air Conditioner'
    },
    {
      iconName: 'radio',
      title: 'Clock-Radio'
    },
    {
      iconName: 'wifi',
      title: 'Wireless Connection'
    },
    {
      iconName: 'microwave',
      title: 'Microwave'
    },
    {
      iconName: 'smoking',
      title: 'Non-Smoking'
    },
    {
      iconName: 'coffee',
      title: 'Tea-Coffee Making'
    },
    {
      iconName: 'view',
      title: 'Views'
    },
    {
      iconName: "miniFridge",
      title: "Mini Fridge"
    },
    {
      iconName: "iron",
      title: "Iron"
    }, {
      iconName: "hairdryer",
      title: "Hair Dryer"
    }, {
      iconName: "ironBoard",
      title: "Iron Board"
    }
  ],
  double_queen_suite: [
    {
      iconName: "Bath",
      title: "Bath"
    },
    {
      iconName: "airConditioner",
      title: "Air Conditioner"
    },
    {
      iconName: "radio",
      title: "Clock-Radio"
    },
    {
      iconName: "wifi",
      title: "Wireless Connection"
    },
    {
      iconName: "microwave",
      title: "Microwave"
    },
    {
      iconName: "smoking",
      title: "Non-Smoking"
    },
    {
      iconName: "coffee",
      title: "Tea-Coffee Making"
    },
    {
      iconName: "miniFridge",
      title: "Mini Fridge",
    },
    {
      iconName: "iron",
      title: "Iron"
    }, {
      iconName: "hairdryer",
      title: "Hair Dryer"
    }, {
      iconName: "ironBoard",
      title: "Iron Board"
    }
  ],
  family_suite: [
    {
      iconName: "Bath",
      title: "Bath"
    },
    {
      iconName: "airConditioner",
      title: "Air Conditioner"
    },
    {
      iconName: "radio",
      title: "Clock-Radio"
    },
    {
      iconName: "wifi",
      title: "Wireless Connection"
    },
    {
      iconName: "microwave",
      title: "Microwave"
    },
    {
      iconName: "smoking",
      title: "Non-Smoking"
    },
    {
      iconName: "coffee",
      title: "Tea-Coffee Making"
    },
    {
      iconName: "miniFridge",
      title: "Mini Fridge"
    },
    {
      iconName: "iron",
      title: "Iron"
    }, {
      iconName: "hairdryer",
      title: "Hair Dryer"
    }, {
      iconName: "ironBoard",
      title: "Iron Board"
    },
    {
      iconName: 'view',
      title: 'Views'
    }
  ]
};

export const GALLERY_INFORMATION = {
  property_and_surroundings: [
    { src: Clarendon_Hotel_1 },
    { src: Clarendon_Hotel_2 },
    { src: Clarendon_Hotel_3 },
    { src: Clarendon_Hotel_4 },
    { src: Clarendon_Hotel_5 },
    { src: Clarendon_Hotel_6 },
    { src: Clarendon_Hotel_7 },
    { src: Clarendon_Hotel_8 }
  ],
  rooms: [
    ...[
      ...ROOMS_IMAGES.deluxe_king_room,
      ...ROOMS_IMAGES.deluxe_king_suite,
      ...ROOMS_IMAGES.deluxe_queen_room,
      ...ROOMS_IMAGES.double_queen_suite,
      ...ROOMS_IMAGES.family_suite
    ]
  ],
  attractions: [
    { src: image1 },
    { src: image2 },
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
    { src: image7 },
    { src: image8 },
    { src: image9 },
    { src: image10 },
    { src: image11 },
    { src: image12 },
    { src: image13 },
    { src: image14 }
  ]
};

export const GALLERY_BUTTON = [
  "Property and surroundings",
  "Rooms",
  "Attractions"
];

export const CONTACT_EMAIL_DEFAULT_VALUES = {
  EMAIL_SUBJECT: "The Clarendon Hotel - Contact Us"
};

export const ATTRACTIONS_CONTENT = [
  {
    title: "Crown Casino",
    address: "Crown Casino, Whiteman Street, Southbank VIC, Australia",
    description:
      "Roll the dice and test your luck at the most opulent Casino in Victoria and indulge yourself in the endless arcades of designer outlets.  Enjoy a lavish, riverside dining experience at one of Crown’s restaurants including Nobu, Conservatory and Rockpool while you watch the famous Crown Casino flame show!",
    image: image1,
    geocode: [-37.8235301, 144.9580953]
  },
  {
    title: "Sea Life Melbourne Aquarium",
    address:
      "SEA LIFE Melbourne Aquarium, King Street, Melbourne VIC, Australia",
    description:
      "See some of the world's most incredible animals from penguins, stingrays to crocodiles and huge sharks!  Sea Life also offers an exclusive underwater dining experience.  ",
    image: image2,
    geocode: [-37.820627, 144.95822]
  },
  {
    title: "South Melbourne Market",
    address:
      "South Melbourne Market, Coventry Street, South Melbourne VIC, Australia",
    description:
      "A rustic cornucopia of the best produce Melbourne has to offer.  With the richness of Melbourne's diversity, and Australia's high quality of produce, browse the market for hand-made pasta, fresh sashimi and Turkish pastries among other delights.",
    image: image3,
    geocode: [-37.8320111, 144.9559391]
  },
  {
    title: "National Gallery of Victoria",
    address:
      "National Gallery of Victoria, Saint Kilda Road, Melbourne VIC, Australia",
    description:
      "A large a formidable building from the outside, an endless labyrinth of rooms rich in art, history and culture on the inside, the NGV (National Gallery of Victoria) is an impressive building that has seen the works from the likes of Picasso, Kaws, Monet and Keith Haring.",
    image: image4,
    geocode: [-37.8225942, 144.9689278]
  },
  {
    title: "Shrine of Remembrance",
    address: "Shrine of Remembrance, Melbourne VIC, Australia",
    description:
      "A sprawling shrine and peace of tranquility dedicated to Australia's fallen soldiers that served in war and peace-keeping missions.  Beautiful architecture and breath-taking scenery makes for a tranquil and introspective visit.",
    image: image5,
    geocode: [-37.8305164, 144.9734319]
  },
  {
    title: "Federation Square",
    address: "Federation Square, Melbourne VIC, Australia",
    description:
      "Federation Square is a venue for arts, culture and public events on the edge of the Melbourne central business district.  Directly across from Flinders Street Train Station and adjacent to the Yarra River, it is a space that is famously Melbournian.",
    image: image6,
    geocode: [-37.8179789, 144.9690576]
  },
  {
    title: "Eureka Skydeck",
    address: "Melbourne Skydeck, Riverside Quay, Southbank VIC, Australia",
    description:
      "The third tallest (as of 2022) building in the Southern Hemisphere, boasting incredible views of the Melbourne skyline.  The Skydeck also includes a lounge and bar.",
    image: image7,
    geocode: [-37.8213341, 144.9646982]
  },
  {
    title: "Collins Street Boutiques",
    address: "Collins Street",
    description:
      "Home to Melbourne’s most fashionable and best dressed. The entire street is packed with luxury stores including Louis Vuitton, Dior, Gucci and Chanel.",
    image: image8,
    geocode: [-37.827912, 144.962909]
  },
  {
    title: "LUME",
    address: "Melbourne Exhibition Centre, South Wharf VIC, Australia",
    description:
      "An incredible immersive experience from that reimagines the works of Monet, Van Gogh and Friends using state-of-the-art projection technology.  Instagram worthy and one of the newest cool exhibits to visit in Melbourne!",
    image: image9,
    geocode: [-37.8255785, 144.9541305]
  },
  {
    title: "St Paul’s Cathedral",
    address: "St Paul's Cathedral, Melbourne",
    description:
      "St Paul's Cathedral is a gorgeous Anglican church that is seat to the Archbishop of Melbourne.  Built in 1675, the mysterious exterior complements the awe-inspiring and breath-taking space inside. ",
    image: image10,
    geocode: [-37.817029, 144.967687]
  },
  {
    title: "ACMI",
    address: "ACMI, Flinders Street, Melbourne VIC, Australia",
    description:
      "ACMI (Australian Centre for the Moving Image) houses a start-of-the-art facility for stop-motion and animation video production.  Adjacent to Federation Square and the bustling life of Swanston Street, ACMI is sure to be a fun way for couples and families.",
    image: image11,
    geocode: [-37.8175974, 144.9686881]
  },
  {
    title: "Polly Woodside",
    address:
      "Polly Woodside, South Wharf Promenade, South Wharf VIC, Australia",
    description:
      "Polly Woodside is a Belfast-built, three-masted, iron-hulled barque, preserved in Melbourne, Victoria, and forming the central feature of the South Wharf precinct. The ship was originally built in Belfast by William J. Woodside and was launched in 1885.",
    image: image12,
    geocode: [-37.824477, 144.953565]
  },
  {
    title: "Albert Park",
    address: "Albert Park Lake, Albert Park VIC, Australia",
    description:
      "Relax is one of Melbourne's premier neighbourhoods with a beautiful brunch or an intimate dinner.  Endless cafes and restaurants such as The Point (picture shown) Misuzu's and Chez Bagou offer gastronomical delights.  Wander further to neighbouring suburbs such as St. Kilda or South Yarra for additional restaurants.",
    image: image13,
    geocode: [-37.8442814, 144.9687557]
  },
  {
    title: "Skinners Adventure Playground",
    address:
      "Skinners Adventure Playground, Dorcas Street, South Melbourne VIC, Australia",
    description:
      "The playground will be open extended hours in school holidays. Please refer to the website for up to date information about Skinners Adventure Playground.",
    image: image14,
    geocode: [-37.8330128, 144.9621889]
  }
];

export const ATTRACTIONS_MARKERS_INFO = [
  {
    geocode: [-37.8235301, 144.9580953],
    popUp: "Crown Casino"
  },
  {
    geocode: [-37.820627, 144.95822],
    popUp: "Sea Life Melbourne Aquarium"
  },
  {
    geocode: [-37.8320111, 144.9559391],
    popUp: "South Melbourne Market"
  },
  {
    geocode: [-37.8225942, 144.9689278],
    popUp: "National Gallery of Victoria"
  },
  {
    geocode: [-37.8305164, 144.9734319],
    popUp: "Shrine of Remembrance"
  },
  {
    geocode: [-37.8179789, 144.9690576],
    popUp: "Federation Square"
  },
  {
    geocode: [-37.8213341, 144.9646982],
    popUp: "Eureka Skydeck"
  },
  {
    geocode: [-37.827912, 144.962909],
    popUp: "Collins Street Boutiques"
  },
  {
    geocode: [-37.8255785, 144.9541305],
    popUp: "LUME"
  },
  {
    geocode: [-37.817029, 144.967687],
    popUp: "St Paul’s Cathedral"
  },
  {
    geocode: [-37.8175974, 144.9686881],
    popUp: "ACMI"
  },
  {
    geocode: [-37.824477, 144.953565],
    popUp: "Polly Woodside"
  },
  {
    geocode: [-37.8442814, 144.9687557],
    popUp: "Albert Park"
  },
  {
    geocode: [-37.8330128, 144.9621889],
    popUp: "Skinners Adventure Playground"
  }
];

export const menuItems = [
  {
    title: 'Home',
    path: '/'
  },
  {
    title: 'Rooms',
    path: '/rooms'
  },
  {
    title: 'Gallery',
    path: '/gallery'
  },
  {
    title: 'Attractions',
    path: '/attractions'
  },

  {
    title: 'About Us',
    path: '/about'
  },
  {
    title: 'Contact Us',
    path: '/contact'
  },
]
